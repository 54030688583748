import { InvariantException } from './InvariantException.ts';

export const UnexpectedTypeException = (
  expectedType: string,
  realValue: unknown,
  additionalMessage?: string,
): Error =>
  InvariantException(
    `Unexpected type. Expected "${expectedType}", got "${realValue}". ${additionalMessage || ''}`,
  );
