export const notNull = <T>(arg: T | null): arg is T => arg !== null;

export const notUndefined = <T>(arg: T | undefined): arg is T => arg !== undefined;

export const notNullNorUndefined = <T>(arg: T | null | undefined): arg is T =>
  arg !== null && arg !== undefined;

export const isMap = <TValue, TKey>(arg: unknown): arg is ReadonlyMap<TKey, TValue> =>
  arg instanceof Map;

export const isSet = <TValue>(arg: unknown): arg is ReadonlySet<TValue> => arg instanceof Set;

export const isArray = <TValue>(arg: unknown): arg is ReadonlyArray<TValue> => Array.isArray(arg);

export const isArrayOf = <TItem>(
  arg: unknown,
  itemTypeGuard: (item: unknown) => item is TItem,
): arg is ReadonlyArray<TItem> => {
  return isArray(arg) && arg.every(itemTypeGuard);
};

export const isRecordOf = <TKey extends string, TValue>(
  arg: unknown,
  keyTypeGuard: (key: string) => key is TKey,
  valueTypeGuard: (value: unknown) => value is TValue,
): arg is ReadonlyRecord<TKey, TValue> => {
  return (
    arg instanceof Object &&
    Object.keys(arg).every(keyTypeGuard) &&
    Object.values(arg).every(valueTypeGuard)
  );
};

export const isDefinedEntry = <TKeyName, TValue>(
  entry: Readonly<[TKeyName, TValue | undefined]>,
): entry is Readonly<[TKeyName, TValue]> => entry[1] !== undefined;
