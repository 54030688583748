import { Collection } from './collectionUtils.ts';
import { isArray, isSet } from './typeguards.ts';

export const areArraysShallowEqual = <TArray extends ReadonlyArray<any>>(
  a: TArray,
  b: TArray,
): boolean => {
  return a.length === b.length && a.every((val, index) => val === b[index]);
};

export const areShallowEqual = <TObject extends AnyObject>(
  a: TObject | null | undefined,
  b: TObject | null | undefined,
  skipKeys: ReadonlyArray<keyof TObject> = [],
): boolean => {
  if (a === b) {
    // handles both same references/values and falsy ones
    return true;
  }

  if ((!a && !b) || !a || !b) {
    return false;
  }

  if (isArray(a) && isArray(b)) {
    return areArraysShallowEqual(a, b);
  }

  if (isSet(a) && isSet(b)) {
    return areArraysShallowEqual(Collection.getValues(a), Collection.getValues(b));
  }

  const aKeys = Object.keys(a).filter((k) => !skipKeys.includes(k));
  const bKeys = Object.keys(b).filter((k) => !skipKeys.includes(k));

  if (aKeys.length !== bKeys.length || aKeys.length === 0) {
    // handles both different set of properties and non-falsy primitive values
    return false;
  }

  return aKeys.every((key) => {
    return a[key] === b[key];
  });
};

export const areArraysMembersShallowEqual = <TArray extends ReadonlyArray<any>>(
  a: TArray,
  b: TArray,
): boolean => {
  return a.length === b.length && a.every((val, index) => areShallowEqual(val, b[index]));
};
