export interface ICustomElementData {
  readonly value: string | null;
  readonly disabled: boolean;
  readonly config: AnyObject | null;
}

interface IIdentifier {
  readonly id: Uuid;
  readonly codename: string;
}

export interface IItemCollectionReference {
  readonly id: Uuid;
}

export interface IItemData {
  readonly id: Uuid;
  readonly codename: string;
  readonly name: string;
  readonly collection: IItemCollectionReference;
}

interface IVariantData extends IIdentifier {}

export interface ICustomElementContext {
  readonly projectId: Uuid;
  readonly item: IItemData;
  readonly variant: IVariantData;
}

export interface ICustomElementClientMessage {
  readonly type: CustomElementClientMessageType;
  readonly data?: CustomElementApi;
  readonly requestId: Uuid;
}

export interface ICustomElementHostMessage {
  readonly type: CustomElementHostMessageType;
  readonly data?: CustomElementApi;
  readonly requestId?: Uuid;
}
export interface ISetValueRequestMessageData {
  readonly value: string | null;
  readonly searchableValue: string | null;
}

export interface ISetValueResponseMessageData {
  readonly error?: string;
}

export interface IMultipleChoiceElementValueItem {
  readonly id: Uuid;
  readonly codename: string;
  readonly name: string;
}

export type ElementValue = string | null | number | IMultipleChoiceElementValueItem[];

interface IItemTypeReference {
  readonly id: Uuid;
  readonly codename: Uuid;
}

export interface IItemExtendedData extends IItemData {
  readonly type: IItemTypeReference;
}

export interface IGetItemDetailsResponseMessageData {
  readonly details: ReadonlyArray<IItemExtendedData> | null;
  readonly error?: string;
}

export interface IItemChangedDetails {
  readonly codename: string;
  readonly name: string;
  readonly collection: IItemCollectionReference;
}

export interface IGetAssetDetailsResponseMessageData {
  readonly details: ReadonlyArray<IAssetDetails> | null;
  readonly error?: string;
}

interface ILanguageReference {
  readonly id: Uuid;
  readonly codename: string;
}

export interface IAssetDescription {
  readonly language: ILanguageReference;
  readonly description: string;
}

export interface IAssetDetails {
  readonly id: Uuid;
  readonly descriptions: Array<IAssetDescription>;
  readonly fileName: string;
  readonly imageHeight: number | null;
  readonly imageWidth: number | null;
  readonly name: string;
  readonly size: number;
  readonly thumbnailUrl: string | null;
  readonly title: string | null;
  readonly type: string;
  readonly url: string | null;
}

export interface IGetValueResponseMessageData {
  readonly value: ElementValue;
  readonly error?: string;
}

export interface IGetValueRequestMessageData {
  readonly codename: string;
}

export interface IGetItemDetailsRequestMessageData {
  readonly itemIds: UuidArray;
}

export interface IGetAssetDetailsRequestMessageData {
  readonly assetIds: UuidArray;
}

export interface ISetHeightRequestMessageData {
  readonly height: number;
}

export interface IDisabledChangedMessageData {
  readonly disabled: boolean;
}

export interface IObserveElementChangesMessageData {
  readonly elements: string[];
}

export interface IElementsChangedMessageData {
  readonly elements: string[];
}

export interface IItemChangedMessageData {
  readonly itemChangedDetails: IItemChangedDetails;
}

export interface IItemSelectionDialogConfig {
  readonly allowMultiple: boolean;
}

export enum FileType {
  All = 'all',
  Images = 'images',
}

export interface IAssetSelectionDialogConfig {
  readonly allowMultiple: boolean;
  readonly fileType: FileType;
}

export interface ISelectedItem {
  readonly id: Uuid;
}

export interface ISelectItemsRequestMessageData {
  readonly config: IItemSelectionDialogConfig;
}

export interface ISelectAssetsRequestMessageData {
  readonly config: IAssetSelectionDialogConfig;
}

export interface ISelectItemsResponseMessageData {
  readonly items: ReadonlyArray<ISelectedItem> | null;
  readonly error?: string;
}

export interface ISelectedAsset {
  readonly id: Uuid;
}

export interface ISelectAssetsResponseMessageData {
  readonly assets: ReadonlyArray<ISelectedAsset> | null;
  readonly error?: string;
}

export interface IInitResponseData {
  readonly element: ICustomElementData;
  readonly context: ICustomElementContext;
}

export type CustomElementApi =
  | IElementsChangedMessageData
  | IItemChangedMessageData
  | ISelectItemsResponseMessageData
  | ISelectAssetsRequestMessageData
  | ISelectAssetsResponseMessageData
  | ISelectItemsRequestMessageData
  | ISetValueRequestMessageData
  | ISetValueResponseMessageData
  | ISetHeightRequestMessageData
  | IDisabledChangedMessageData
  | IGetValueResponseMessageData
  | IGetValueRequestMessageData
  | IGetItemDetailsRequestMessageData
  | IGetAssetDetailsRequestMessageData
  | IGetAssetDetailsResponseMessageData
  | IGetItemDetailsResponseMessageData
  | IInitResponseData;

export enum CustomElementClientMessageType {
  InitDataRequest = 'init-data-request',
  SelectAssetsRequest = 'select-assets-request',
  SelectItemsRequest = 'select-items-request',
  SetValueRequest = 'set-value-request',
  SetHeightRequest = 'set-height-request',
  GetValueRequest = 'get-value-request',
  GetAssetDetailsRequest = 'get-asset-details-request',
  GetItemDetailsRequest = 'get-item-details-request',
  ObserveElementChanges = 'observe-element-changes',
  ObserveItemChanges = 'observe-item-changes',
}

export enum CustomElementHostMessageType {
  InitDataResponse = 'init-data-response',
  SetValueResponse = 'set-value-response',
  OnDisabledChanged = 'on-disabled-changed',
  GetValueResponse = 'get-value-response',
  GetAssetDetailsResponse = 'get-asset-details-response',
  GetItemDetailsResponse = 'get-item-details-response',
  OnElementsChanged = 'on-elements-changed',
  OnItemChanged = 'on-item-changed',
  SelectAssetsResponse = 'select-assets-response',
  SelectItemsResponse = 'select-items-response',
}
