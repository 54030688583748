import { isArray } from './typeguards.ts';

export const groupBy = <T>(
  list: ReadonlyArray<T>,
  keyGetter: (value: T) => string,
): Map<string, readonly T[]> =>
  list.reduce((accumulator, current) => {
    const key = keyGetter(current);
    const collection = accumulator.get(key);
    return collection
      ? accumulator.set(key, [...collection, current])
      : accumulator.set(key, [current]);
  }, new Map<string, readonly T[]>());

export const toArray = <T>(arg: T | ReadonlyArray<T>): ReadonlyArray<T> =>
  isArray(arg) ? arg : [arg];
