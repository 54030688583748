import { isString } from '../stringUtils.ts';

export const uuidPattern =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const uuidRegex = new RegExp(`^${uuidPattern}$`);

export function isUuid(arg: unknown): arg is Uuid {
  return isString(arg) && uuidRegex.test(arg);
}

export function isUserId(arg: string): arg is UserId {
  return !!arg.trim().length;
}
