import { UnexpectedTypeException } from '../errors/UnexpectedTypeException.ts';
import { isArray, isMap, isSet } from './typeguards.ts';

const getItemCount = <TItem>(collection: ReadonlyArray<TItem> | ReadonlySet<TItem>): number => {
  return isSet(collection) ? collection.size : collection.length;
};

const getMappedMapEntries = <TKey, TValue, TResult>(
  map: ReadonlyMap<TKey, TValue>,
  selector: (k: TKey, v: TValue) => TResult,
): ReadonlyArray<TResult> => {
  const result: TResult[] = [];
  map.forEach((value, key) => result.push(selector(key, value)));

  return result;
};

const getSetValues = <TValue>(set: ReadonlySet<TValue>): ReadonlyArray<TValue> => {
  const result: TValue[] = [];
  set.forEach((value) => result.push(value));

  return result;
};

/**
 * Returns all keys from the received map.
 * @param map
 */
const getKeys = <TKey>(map: ReadonlyMap<TKey, unknown>): ReadonlyArray<TKey> =>
  getMappedMapEntries(map, (k) => k);

/**
 * Returns all entries from the received map as an array of tuples [key, value].
 * @param map
 */
const getEntries = <TKey, TValue>(
  map: ReadonlyMap<TKey, TValue>,
): ReadonlyArray<readonly [TKey, TValue]> => getMappedMapEntries(map, (k, v) => [k, v]);

/**
 * Returns all values from the received map or set.
 * @param mapOrSet
 */
const getValues = <TValue>(
  mapOrSet: ReadonlyMap<unknown, TValue> | ReadonlySet<TValue>,
): ReadonlyArray<TValue> => {
  if (isMap(mapOrSet)) {
    return getMappedMapEntries(mapOrSet, (_, v) => v);
  }

  if (isSet(mapOrSet)) {
    return getSetValues(mapOrSet);
  }

  throw UnexpectedTypeException('Map | Set', mapOrSet);
};

function remove<TKey, TValue>(
  map: ReadonlyMap<TKey, TValue>,
  toRemove: TKey,
): ReadonlyMap<TKey, TValue>;
function remove<TValue>(set: ReadonlySet<TValue>, toRemove: TValue): ReadonlySet<TValue>;
/**
 * Returns a new map or set with the specified key (map) or value (set) removed if present.
 * Returns the same map or set if the specified key or value doesn't exist
 * @param collection Map or Set
 * @param toRemove map key or set value
 */
function remove<TKey, TValue>(
  collection: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  toRemove: TValue | TKey,
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    return removeMany(collection, [toRemove as TKey]);
  }

  if (isSet(collection)) {
    return removeMany(collection, [toRemove as TValue]);
  }

  throw UnexpectedTypeException('Map | Set', collection);
}

function removeMany<TKey, TValue>(
  map: ReadonlyMap<TKey, TValue>,
  toRemove: ReadonlyArray<TKey>,
): ReadonlyMap<TKey, TValue>;
function removeMany<TValue>(
  set: ReadonlySet<TValue>,
  toRemove: ReadonlyArray<TValue>,
): ReadonlySet<TValue>;
/**
 * Returns a new map or set with the specified keys (map) or values (set) removed if present.
 * Returns the same map or set if none of the specified keys or values doesn't exist
 * @param collection Map or Set
 * @param toRemove an array of map keys or set values
 */
function removeMany<TKey, TValue>(
  collection: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  toRemove: ReadonlyArray<TValue> | ReadonlyArray<TKey>,
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    const keysToRemove = toRemove as ReadonlyArray<TKey>;
    if (keysToRemove.every((k) => !collection.has(k))) {
      return collection;
    }
    const result = new Map(collection);
    keysToRemove.forEach((key) => result.delete(key));
    return result;
  }

  if (isSet(collection)) {
    const valuesToRemove = toRemove as ReadonlyArray<TValue>;
    if (valuesToRemove.every((v) => !collection.has(v))) {
      return collection;
    }
    const result = new Set(collection);
    (toRemove as ReadonlyArray<TValue>).forEach((value) => result.delete(value));
    return result;
  }

  throw UnexpectedTypeException('Map | Set', collection);
}

function add<TValue, TKey>(
  map: ReadonlyMap<TKey, TValue>,
  toAdd: readonly [TKey, TValue],
  existingKeyResolver?: (newValue: TValue, existingValue: TValue, key: TKey) => TValue,
): ReadonlyMap<TKey, TValue>;
function add<TValue>(set: ReadonlySet<TValue>, toAdd: TValue): ReadonlySet<TValue>;
/**
 * Returns a new map or set with the specified entry (map) or value (set) added.
 * note: For maps you can supply existingKeyResolver that can customize the value if the key in the supplied entry already exists in the map.
 * @param collection Map or Set
 * @param toAdd map entry or set values to add
 * @param existingKeyResolver optional function accepting newValue, oldValue and a key and returning value to be used with the key
 */
function add<TValue, TKey = never>(
  collection: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  toAdd: TValue | readonly [TKey, TValue],
  existingKeyResolver: (newValue: TValue, existingValue: TValue, key: TKey) => TValue = (v) => v,
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    return addMany(collection, [toAdd as readonly [TKey, TValue]], existingKeyResolver);
  }
  if (isSet(collection)) {
    return addMany(collection, [toAdd as TValue]);
  }

  throw UnexpectedTypeException('Map | Set', collection);
}

function addMany<TKey, TValue>(
  map: ReadonlyMap<TKey, TValue>,
  toAdd: ReadonlyArray<readonly [TKey, TValue]>,
  existingKeyResolver?: (newValue: TValue, existingValue: TValue, key: TKey) => TValue,
): ReadonlyMap<TKey, TValue>;
function addMany<TValue>(
  set: ReadonlySet<TValue>,
  toAdd: ReadonlyArray<TValue>,
): ReadonlySet<TValue>;
/**
 * Returns a new map or set with the specified entries (map) or values (set) added.
 * note: For maps you can supply existingKeyResolver that can customize values if a key in some entry already exists in the map.
 * @param collection Map or Set
 * @param toAdd an array of map entries or set values to add
 * @param existingKeyResolver optional function accepting newValue, oldValue and a key and returning value to ve used with the key
 */
function addMany<TValue, TKey = never>(
  collection: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  toAdd: ReadonlyArray<TValue> | ReadonlyArray<readonly [TKey, TValue]>,
  existingKeyResolver: (newValue: TValue, existingValue: TValue, key: TKey) => TValue = (v) => v,
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    const result = new Map(collection);
    (toAdd as ReadonlyArray<readonly [TKey, TValue]>).forEach(([key, value]) =>
      result.set(
        key,
        result.has(key) ? existingKeyResolver(value, result.get(key) ?? value, key) : value,
      ),
    );
    return result;
  }

  if (isSet(collection)) {
    const result = new Set(collection);
    (toAdd as ReadonlyArray<TValue>).forEach((value) => result.add(value));
    return result;
  }

  throw UnexpectedTypeException('Map | Set', collection);
}

function replace<TValue>(
  array: ReadonlyArray<TValue>,
  index: number,
  newValue: TValue,
): ReadonlyArray<TValue>;
function replace<TValue, TKey>(
  map: ReadonlyMap<TKey, TValue>,
  key: TKey,
  newValue: TValue,
): ReadonlyMap<TKey, TValue>;
/**
 * Returns a new array or map with the specified key (map) or index (array) replaced with the supplied value.
 * Returns the same array or map if the specified key or index doesn't exist.
 * @param collection Array or Map
 * @param indexOrKey index (array) or key (map) to be replaced
 * @param newValue new value to replace with
 */
function replace<TValue, TKey>(
  collection: ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue>,
  indexOrKey: number | TKey,
  newValue: TValue,
): ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue> {
  return replaceWith(collection as ReadonlyMap<TKey, TValue>, indexOrKey as TKey, () => newValue);
}

function replaceWith<TValue>(
  array: ReadonlyArray<TValue>,
  index: number,
  updater: (oldValue: TValue) => TValue,
): ReadonlyArray<TValue>;
function replaceWith<TValue, TKey>(
  map: ReadonlyMap<TKey, TValue>,
  key: TKey,
  updater: (oldValue: TValue) => TValue,
): ReadonlyMap<TKey, TValue>;
/**
 * Returns a new array or map with the specified key (map) or index (array) replaced with the result of the supplied updater function applied to the existing value associated with the provided indexOrKey.
 * Returns the same array or map if the specified key or index doesn't exist.
 * @param collection Array or Map
 * @param indexOrKey index (array) or key (map) to be replaced
 * @param updater function to apply to the value associated with the provided indexOrKey
 */
function replaceWith<TValue, TKey>(
  collection: ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue>,
  indexOrKey: number | TKey,
  updater: (oldValue: TValue) => TValue,
): ReadonlyArray<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    const key = indexOrKey as TKey;
    if (!collection.has(key)) {
      return collection;
    }

    const result = new Map(collection);
    const oldValue = result.get(key) as TValue;

    result.set(key, updater(oldValue));
    return result;
  }

  if (isArray(collection)) {
    const index = indexOrKey as number;
    return collection.length <= index
      ? collection
      : collection.map((oldValue, i) => (i === index ? updater(oldValue) : oldValue));
  }

  throw UnexpectedTypeException('Map | Array', collection);
}

function togglePresence<TValue>(set: ReadonlySet<TValue>, item: TValue): ReadonlySet<TValue>;
function togglePresence<TValue>(array: ReadonlyArray<TValue>, item: TValue): ReadonlyArray<TValue>;
/**
 * Returns a new array or set with the item:
 * Added if the item doesn't exist in the set or array.
 * Removed if the item does exist in the array or set.
 * Note: items are compared using (===) for equality
 * @param collection Set or Array
 * @param item item to be removed or added
 */
function togglePresence<TValue>(
  collection: ReadonlyArray<TValue> | ReadonlySet<TValue>,
  item: TValue,
): ReadonlyArray<TValue> | ReadonlySet<TValue> {
  if (isSet(collection)) {
    return collection.has(item) ? removeMany(collection, [item]) : addMany(collection, [item]);
  }

  if (isArray(collection)) {
    return collection.includes(item) ? collection.filter((i) => i !== item) : [...collection, item];
  }

  throw UnexpectedTypeException('Set | Array', collection);
}

type ReturnsLastTupleOrArrayItem<
  TValue,
  TArrayOrTuple extends ReadonlyArray<TValue>,
> = TArrayOrTuple extends [...ReadonlyArray<TValue>, infer TLast]
  ? TLast
  : TArrayOrTuple[number] | null;
/**
 * Returns the last element of the array or tuple, or null for an empty array.
 * @param arrayOrTuple
 */
const getLast = <TValue, TArrayOrTuple extends ReadonlyArray<TValue>>(
  arrayOrTuple: TArrayOrTuple,
): ReturnsLastTupleOrArrayItem<TValue, TArrayOrTuple> => {
  const lastIndex = arrayOrTuple.length - 1;
  return (lastIndex > -1 ? arrayOrTuple[lastIndex] : null) as ReturnsLastTupleOrArrayItem<
    TValue,
    TArrayOrTuple
  >;
};

type ReturnsFirstTupleOrArrayItem<
  TValue,
  TArrayOrTuple extends ReadonlyArray<TValue>,
> = TArrayOrTuple extends [infer TFirst, ...ReadonlyArray<TValue>]
  ? TFirst
  : TArrayOrTuple[0] | null;
/**
 * Returns the first element of the array or tuple, or null for an empty array.
 * @param arrayOrTuple
 */
const getFirst = <TValue, TArrayOrTuple extends ReadonlyArray<TValue>>(
  arrayOrTuple: TArrayOrTuple,
): ReturnsFirstTupleOrArrayItem<TValue, TArrayOrTuple> => {
  const hasItems = arrayOrTuple.length > 0;
  return (hasItems ? arrayOrTuple[0] : null) as ReturnsFirstTupleOrArrayItem<TValue, TArrayOrTuple>;
};

/**
 * Returns intersection of array with either another array or set
 * When set is provided as the second parameter, the complexity is O(m*log(n)), otherwise O(m*n)
 * If possible and large amount of data is involved, make a set from the larger of the two inputs
 * Note: Each input must contain unique elements, otherwise, the result of the operation is not defined
 * @param arr First array
 * @param collection Second array or set
 */
const intersect = <TItem>(
  arr: ReadonlyArray<TItem>,
  collection: ReadonlyArray<TItem> | ReadonlySet<TItem>,
): ReadonlyArray<TItem> => {
  if (isSet(collection)) {
    return arr.filter((value) => collection.has(value));
  }
  return arr.filter((value) => collection.includes(value));
};

/**
 * Tests whether all items of {subset} are actually included in {array}.
 * @param array The supposedly bigger collection.
 * @param subset The supposedly smaller collection included in the {array}
 * @return {true} if all items of {subset} are indeed included in {array}.
 */
const isSubset = <TItem>(
  array: ReadonlyArray<TItem>,
  subset: ReadonlyArray<TItem> | ReadonlySet<TItem>,
): boolean => {
  return intersect(array, subset).length === getItemCount(subset);
};

function filter<TValue, TKey>(
  map: ReadonlyMap<TKey, TValue>,
  predicate: (value: TValue) => boolean,
): ReadonlyMap<TKey, TValue>;
function filter<TValue>(
  set: ReadonlySet<TValue>,
  predicate: (value: TValue) => boolean,
): ReadonlySet<TValue>;
/**
 * Returns a new map or set filtered with the specified predicate on values
 * @param collection Map or Set
 * @param predicate Predicate for filtering the values
 */
function filter<TValue, TKey = never>(
  collection: ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
  predicate: (value: TValue) => boolean,
): ReadonlySet<TValue> | ReadonlyMap<TKey, TValue> {
  if (isMap(collection)) {
    return new Map<TKey, TValue>(getEntries(collection).filter(([, value]) => predicate(value)));
  }
  if (isSet(collection)) {
    return new Set<TValue>([...collection].filter(predicate));
  }

  throw UnexpectedTypeException('Map | Set', collection);
}

const isEmpty = <TValue, TKey = never>(
  collection: readonly TValue[] | ReadonlySet<TValue> | ReadonlyMap<TKey, TValue>,
): boolean => {
  return isSet(collection) || isMap(collection) ? collection.size === 0 : collection.length === 0;
};

export const Collection = {
  add,
  addMany,
  filter,
  getEntries,
  getFirst,
  getKeys,
  getLast,
  getValues,
  intersect,
  isEmpty,
  isSubset,
  remove,
  removeMany,
  replace,
  replaceWith,
  togglePresence,
} as const;
