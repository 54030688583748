/**
 * Removes properties with undefined values from the given object
 * @param o Input object
 * @returns New object with properties with undefined values filtered out
 */
export const removeUndefinedProps = <T extends ReadonlyRecord<string, unknown>>(
  o: T,
): { readonly [key in keyof T]: NonNullable<T[key]> } =>
  Object.fromEntries(Object.entries(o).filter(([, v]) => v !== undefined)) as {
    readonly [key in keyof T]: NonNullable<T[key]>;
  };
