export const identity = <T>(x: T): T => x;

export const noOperation = () => undefined;

export const noOperationAsync = () => Promise.resolve();

export type Executor<T extends any[]> = (...args: T) => void;
export type CancellableExecutor<T extends any[]> = Executor<T> & { readonly cancel: () => void };

export function createDeferredFunctionWithCheck<T extends any[]>(
  checkWait: () => boolean,
  checkInterval: number,
  executor: Executor<T>,
): CancellableExecutor<T> {
  let timeoutId: number | null = null;

  const deferredFunction = function (this: any, ...args: any[]): void {
    deferredFunction.cancel();

    let firstRun = true;
    const waitOrExecute = () => {
      if (firstRun || checkWait()) {
        firstRun = false;
        timeoutId = self.setTimeout(
          () => {
            timeoutId = null;
            waitOrExecute();
          },
          firstRun ? 0 : checkInterval,
        );
      } else {
        executor.apply(this, args);
      }
    };

    waitOrExecute();
  };

  deferredFunction.cancel = (): void => {
    if (timeoutId) {
      self.clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return deferredFunction;
}
