/**
 * Creates new object as a mapped result of values from the received object.
 * @param o Input object
 * @param mapper Mapper function receiving current value and key. Returns new value for the given key.
 * @returns New object
 */
export const mapObjectValues = <T extends ReadonlyRecord<string, unknown>, TNewValue>(
  o: T,
  mapper: (value: T[keyof T], key: keyof T) => TNewValue,
): { readonly [key in keyof T]: TNewValue } =>
  Object.fromEntries(Object.entries(o).map(([k, v]) => [k, mapper(v as T[keyof T], k)])) as {
    readonly [key in keyof T]: TNewValue;
  };
