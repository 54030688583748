import {
  CancelledPromiseError,
  ICancellablePromise,
  makeCancellable,
} from './cancellablePromise.ts';
import { noOperation } from './generalUtilities.ts';

/**
 * Example:
 * useEffect(() => {
 *  const delayedAction = delay(1_000)
 *    .then(() => doAction(argument))
 *    .catch(swallowCancelledPromiseError);
 *
 *  return () => delayedAction.cancel();
 * }, [argument]);
 */
export function delay(duration: number): ICancellablePromise {
  let timeoutId: number | null = null;
  let performCancellation: () => void = noOperation;
  const thePromise = new Promise<void>((resolve, reject) => {
    timeoutId = self.setTimeout(resolve, duration);

    performCancellation = () => {
      self.clearTimeout(timeoutId ?? undefined);
      reject(new CancelledPromiseError('Delayed Promise was cancelled.'));
    };
  });

  return makeCancellable(thePromise, performCancellation);
}
