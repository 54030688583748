import { trimEnd, trimStart } from './textUtils.ts';

export const template =
  (strings: TemplateStringsArray, ...keys: number[]) =>
  (...values: unknown[]) => {
    const dict: any = values[values.length - 1] || {};
    const result = [strings[0]];
    keys.forEach((key, i) => {
      const value = Number.isInteger(key) ? values[key] : dict[key];
      result.push(value, strings[i + 1]);
    });
    return result.join('');
  };

export const isEmptyOrWhitespace = (value: string | null | undefined): boolean =>
  !value || !value.trim().length;

export const startsWithWhitespace = (value: string | null | undefined): boolean => {
  const withTrimmedStart = trimStart(value);
  return !value || value.length !== withTrimmedStart.length;
};

export const endsWithWhitespace = (value: string | null | undefined): boolean => {
  const withTrimmedEnd = trimEnd(value);
  return !value || value.length !== withTrimmedEnd.length;
};

export const capitalizeFirstLetter = (str: string): string => {
  const charArray = Array.from(str);

  return [(charArray[0] ?? '').toUpperCase(), ...charArray.slice(1)].join('');
};

export const pluralizeWithoutCount = (message: string, count: number | null | undefined) =>
  count === 1 ? message : `${message}s`;

export const pluralizeWithCount = (message: string, count: number | null | undefined) =>
  `${count} ${pluralizeWithoutCount(message, count)}`;

export const singularizeWithoutCount = (message: string, count: number | null | undefined) =>
  count === 1 ? `${message}s` : message;

export const makeSpacesUnbreakable = (value: string | null | undefined): string =>
  value?.replace(/ /g, '\u00a0') ?? '';

export const joinWithOxfordComma = (strings: ReadonlyArray<string>): string => {
  if (strings.length < 3) {
    return strings.join(' and ');
  }

  return `${strings.slice(0, strings.length - 1).join(', ')}, and ${strings[strings.length - 1]}`;
};

export function isString(arg: unknown): arg is string {
  return typeof arg === 'string';
}

export const trimAndRemoveMultipleSpaces = (str: string): string =>
  str.trim().replace(/\s\s+/g, ' ');

export const getShortenedTextWithEllipsis = (text: string, maxLength: number): string =>
  text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

export const replaceEmptyStringWithDash = (str: string): string =>
  isEmptyOrWhitespace(str) ? '—' : str;

export const removeTrailingZeros = (value: string): string => {
  if (value.endsWith('.')) {
    return value.slice(0, value.length - 1);
  }

  return value.endsWith('0') ? removeTrailingZeros(value.slice(0, value.length - 1)) : value;
};

export const containsWhitespace = (url: string): boolean => /\s/g.test(url);

const digitRegex = /\d/;
export const startsWithDigit = (str: string): boolean =>
  str.length > 0 && digitRegex.test(str.charAt(0));
