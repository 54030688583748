function AssertionError(message: string): Error {
  const error = new Error(message);
  error.name = 'AssertionError';

  return error;
}

/**
 * The function asserts that the condition is met. TypeScript can understand the asserted type. If the condition is not met, an error is thrown.
 * @param condition Any primitive or referenced value.
 * @param createErrorMessage Lazily evaluated function. For performance reasons the message is created only when the condition is not met.
 */
export function assert(condition: any, createErrorMessage: () => string): asserts condition {
  if (!condition) {
    const message = createErrorMessage();

    throw AssertionError(message);
  }
}
