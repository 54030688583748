import { Ref, RefObject } from 'react';

export function isRefObject<TInstance>(ref?: Ref<TInstance>): ref is RefObject<TInstance> {
  return !!ref && typeof ref === 'object' && Object.hasOwn(ref, 'current');
}

export type RefCallback<TInstance> = (instance: TInstance | null) => void;

export function isRefCallback<TInstance>(ref?: Ref<TInstance>): ref is RefCallback<TInstance> {
  return !!ref && typeof ref === 'function';
}
