export const SPACE = ' ';
export const EMPTY_STRING = '';
const REGEX_CR = /\r/g;
const REGEX_LF = /\n/g;
const REGEX_ZERO_WIDTH_SPACE = /&#8203;?/g;
const REGEX_NBSP = /&nbsp;?/g;

export function removeNewLinesAndDoubledWhitespace(text: string | null | undefined): string {
  if (!text) {
    return EMPTY_STRING;
  }
  return (
    text
      .replace(REGEX_CR, SPACE)
      .replace(REGEX_LF, SPACE)
      .replace(REGEX_NBSP, SPACE)
      .replace(REGEX_ZERO_WIDTH_SPACE, EMPTY_STRING)
      // Replace any two or more whitespace characters with one space
      .replace(/\s\s+/g, SPACE)
  );
}

export function trimStart(text: string | null | undefined): string {
  if (!text) {
    return EMPTY_STRING;
  }
  return text.replace(/^\s+/, EMPTY_STRING);
}

export function trimEnd(text: string | null | undefined): string {
  if (!text) {
    return EMPTY_STRING;
  }
  return text.replace(/\s+$/, EMPTY_STRING);
}

export function getCharacterWithoutWhitespacesCount(text: string | null | undefined): number {
  if (!text) {
    return 0;
  }
  const regex = /\S/g; // Any character except white space.
  const chars = text.match(regex);
  return chars ? chars.length : 0;
}

export function getCharacterWithoutLineBreaksCount(text: string | null | undefined): number {
  if (!text) {
    return 0;
  }
  const regex = /[^\n\r\f]/g; // Any character except line breaks.
  const chars = text.match(regex);
  return chars ? chars.length : 0;
}

export function getWordCount(text: string | null | undefined): number {
  if (!text) {
    return 0;
  }
  const regex = /\S+/g; // Matches any character that is not a whitespace character (spaces, tabs, line breaks).
  const wordArray = text.match(regex);
  return wordArray ? wordArray.length : 0;
}
