import { Collection } from './collectionUtils.ts';

type Ordering = 'Ascending' | 'Descending' | 'None';
type CompareFunction<T> = (a: T, b: T) => number;
type SelectPropFunction<TObj, TProp> = (obj: TObj) => TProp;

interface IParams<TObj, TProp> {
  readonly compare: CompareFunction<TProp>;
  readonly direction?: Ordering;
  readonly select: SelectPropFunction<TObj, TProp>;
}

export const createCompare = <TObj, TProp>(params: IParams<TObj, TProp>): CompareFunction<TObj> => {
  const { compare, direction = 'Ascending', select } = params;

  return (obj: TObj, other: TObj): number => {
    const prop = select(obj);
    const otherProp = select(other);

    const result = compare(prop, otherProp);

    return direction === 'Ascending' ? result : -result;
  };
};

export const alphabetically = (a: string, b: string): number => a.localeCompare(b);

export const chronologically = (a: DateTimeStamp, b: DateTimeStamp): number =>
  Date.parse(a) - Date.parse(b);

export const numerically = (a: number, b: number): number => a - b;

export const logically = (a: boolean, b: boolean): number => numerically(Number(a), Number(b));

type RawFragmentPair = FragmentPair<string | null>;
type FragmentPair<TValue> = Readonly<[valueXSourceA: TValue, valueXSourceB: TValue]>;

export const naturally = (a: string, b: string): number => {
  const fragmentsSourceA = splitOnNumericFragments(a);
  const fragmentsSourceB = splitOnNumericFragments(b);
  const fragmentPairs = zipWithFill(fragmentsSourceA, fragmentsSourceB);
  const indexOfFirstDifferingFragmentPair = findIndexOfFirstDifferingFragmentPair(fragmentPairs);
  const fragmentsSinceDiffering = fragmentPairs.slice(indexOfFirstDifferingFragmentPair);
  const firstDifferingFragmentPair = Collection.getFirst(fragmentsSinceDiffering);

  if (!isFragmentComplete(firstDifferingFragmentPair)) {
    return compareIncompleteFragmentPair(firstDifferingFragmentPair);
  }

  const numericFragmentPair = parseNumbersFromTextFragmentPair(firstDifferingFragmentPair);
  if (numericFragmentPair.every((fragment) => !Number.isNaN(fragment))) {
    return compareNumericFragmentPair(numericFragmentPair, firstDifferingFragmentPair);
  }

  return compareStringFragmentPair(firstDifferingFragmentPair, fragmentsSinceDiffering);
};

const zipWithFill = (
  fragmentsSourceA: ReadonlyArray<string>,
  fragmentsSourceB: ReadonlyArray<string>,
): ReadonlyArray<RawFragmentPair> => {
  const sizeOfTheBiggerArray = Math.max(fragmentsSourceA.length, fragmentsSourceB.length);
  return Array(sizeOfTheBiggerArray)
    .fill(null)
    .map((_, index) => [fragmentsSourceA[index] ?? null, fragmentsSourceB[index] ?? null]);
};

const splitOnNumericFragments = (s: string): ReadonlyArray<string> =>
  s.split(/(\d+)/).filter((value) => value !== '');

const findIndexOfFirstDifferingFragmentPair = (
  fragmentPairs: ReadonlyArray<RawFragmentPair>,
): number => fragmentPairs.findIndex(([a, b]) => a !== b);

const isFragmentComplete = (
  fragmentPair: RawFragmentPair | null,
): fragmentPair is FragmentPair<string> =>
  !!fragmentPair && fragmentPair[0] !== null && fragmentPair[1] !== null;

const parseNumbersFromTextFragmentPair = <TValue extends string>([
  valueXSourceA,
  valueXSourceB,
]: FragmentPair<TValue>): FragmentPair<number> => {
  return [Number.parseInt(valueXSourceA, 10), Number.parseInt(valueXSourceB, 10)];
};

const countLeadingZeros = (s: string): number => {
  const match = /^0*/.exec(s);
  const leadingZeros = match?.[0] ?? '';
  return leadingZeros.length;
};

const compareIncompleteFragmentPair = (
  firstDifferingFragmentPair: RawFragmentPair | null,
): number => {
  const textXSourceA = firstDifferingFragmentPair?.[0] ?? null;
  const textXSourceB = firstDifferingFragmentPair?.[1] ?? null;
  return (textXSourceA ? 1 : 0) - (textXSourceB ? 1 : 0);
};

const compareNumericFragmentPair = (
  numericFragmentPair: FragmentPair<number>,
  firstDifferingFragmentPair: FragmentPair<string>,
): number => {
  const [numberXSourceA, numberXSourceB] = numericFragmentPair;
  const [textXSourceA, textXSourceB] = firstDifferingFragmentPair;
  return numberXSourceA !== numberXSourceB
    ? numerically(numberXSourceA, numberXSourceB)
    : -numerically(countLeadingZeros(textXSourceA), countLeadingZeros(textXSourceB));
};

const compareStringFragmentPair = (
  firstDifferingFragmentPair: FragmentPair<string>,
  fragmentsSinceDiffering: ReadonlyArray<RawFragmentPair>,
): number => {
  const [textXSourceA, textXSourceB] = firstDifferingFragmentPair;
  const fragmentFollowingTheDifferingOne = fragmentsSinceDiffering[1] ?? null;
  const [nextTextXSourceA, nextTextXSourceB] = fragmentFollowingTheDifferingOne ?? ['', ''];
  return alphabetically(textXSourceA + nextTextXSourceA, textXSourceB + nextTextXSourceB);
};

type Comparer<TItem> = (a: TItem, b: TItem) => number;
/**
 * Orders a collection (not in-situ) using multiple comparer functions
 * @param comparers A prioritized list of comparer methods. The most important at the start. If the most important comparer cannot decide (returns 0) the next comparer is used.
 */
export const compareByMultipleFactors = <TItem>(
  ...comparers: readonly [Comparer<TItem>, Comparer<TItem>, ...ReadonlyArray<Comparer<TItem>>]
): Comparer<TItem> => {
  return (a, b) =>
    comparers.reduce((previousResult, comparer) => previousResult || comparer(a, b), 0);
};
